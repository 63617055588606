import "dotenv/config";

import * as React from "react";
import { Admin, Resource } from "react-admin";
import UserIcon from "@material-ui/icons/People";
import ClientsIcon from "@material-ui/icons/PeopleAlt";
import EventIcon from "@material-ui/icons/Event";
import EventNoteIcon from "@material-ui/icons/EventNote";
import DashboardIcon from "@material-ui/icons/Dashboard";
import HouseIcon from "@material-ui/icons/House";
import dutchMessages from "ra-language-dutch";
import polyglotI18nProvider from "ra-i18n-polyglot";
import { Route } from "react-router-dom";
import { UserList, UserEdit, UserCreate, UserShow } from "./components/users";
import {
  ReservationList,
  ReservationEdit,
  ReservationCreate,
  ReservationShow,
} from "./components/reservations";
import {
  PropertyList,
  PropertyEdit,
  PropertyCreate,
  PropertyShow,
} from "./components/properties";
import {
  ClientList,
  ClientEdit,
  ClientCreate,
  ClientShow,
} from "./components/clients";
import {
  AvailabilityEdit,
  AvailabilityList,
  AvailabilityShow,
  AvailabilityCreate,
} from "./components/availability";
import {
  PortalCreate,
  PortalEdit,
  PortalList,
  PortalShow,
} from "./components/portals";
import authProvider from "./framework/authProvider";
import dataProviders from "./dataProvider/index";
import Login from "./layout/login/index";
import Account from "./layout/account";
import PortalSettingsEdit from "./layout/portal";
import Layout from "./layout/layout";
import resourceTranslation from "./i18n/resources";

const roles = ["superuser", "admin", "user"];
const messages = {
  nl: {
    ...dutchMessages,
    ...resourceTranslation,
  },
};
const i18nProvider = polyglotI18nProvider((locale) => messages["nl"]);

const App = () => (
  <Admin
    loginPage={Login}
    dataProvider={dataProviders}
    authProvider={authProvider}
    layout={Layout}
    locale="nl"
    i18nProvider={i18nProvider}
    customRoutes={[
      <Route key="account" path="/account" component={Account} />,
      <Route key="layout" path="/portal" component={PortalSettingsEdit} />,
    ]}
  >
    {(permissions) => [
      roles.includes(permissions) ? (
        <Resource
          options={{ label: "Gebruikersbeheer" }}
          icon={UserIcon}
          name="users"
          list={permissions === "superuser" ? UserList : null}
          edit={UserEdit}
          create={UserCreate}
          show={UserShow}
        />
      ) : null,
      roles.includes(permissions) ? (
        <Resource
          options={{ label: "Portalen" }}
          icon={DashboardIcon}
          name="portals"
          list={permissions === "superuser" ? PortalList : null}
          edit={PortalEdit}
          create={PortalCreate}
          show={PortalShow}
        />
      ) : null,
      roles.includes(permissions) ? <Resource name="layouts" /> : null,
      roles.includes(permissions) ? (
        <Resource
          options={{ label: "Accomodaties" }}
          icon={HouseIcon}
          name="properties"
          list={PropertyList}
          edit={PropertyEdit}
          create={PropertyCreate}
          show={PropertyShow}
        />
      ) : null,
      roles.includes(permissions) ? (
        <Resource
          options={{ label: "Reserveringen" }}
          icon={EventNoteIcon}
          name="reservations"
          list={ReservationList}
          edit={ReservationEdit}
          create={ReservationCreate}
          show={ReservationShow}
        />
      ) : null,
      roles.includes(permissions) ? (
        <Resource
          options={{ label: "Beschikbaarheid" }}
          icon={EventIcon}
          name="availability"
          list={AvailabilityList}
          edit={AvailabilityEdit}
          show={AvailabilityShow}
          create={AvailabilityCreate}
        />
      ) : null,
      roles.includes(permissions) ? (
        <Resource
          options={{ label: "Klanten" }}
          icon={ClientsIcon}
          name="clients"
          list={ClientList}
          edit={ClientEdit}
          create={ClientCreate}
          show={ClientShow}
        />
      ) : null,
    ]}
  </Admin>
);

export default App;
