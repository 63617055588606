import { createStyles, makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) =>
  createStyles({
    link: {
      color: "#fff",
    },
    container: {
      background: theme.palette.secondary.main,
      margin: "0",
      width: "100%",
      padding: "20px",
      heigth: "35px",
    },
    margin: {
      margin: theme.spacing(1),
    },
    noPadding: {
      padding: "2px 0 0 0 !important",
    },
  })
);
