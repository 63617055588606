import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    background: `url('${require("../../assets/holiday_vorden.jpg")}')`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  card: {
    margin: "auto",
    padding: "35px",
  },
  avatar: {
    margin: "1rem",
    display: "flex",
    justifyContent: "center",
    height: 80,
    width: 80,
    margin: "auto",
  },
  icon: {
    backgroundColor: theme.palette.primary.light,
    height: 60,
    width: 60,
  },
  form: {
    padding: "0 1rem 1em 1em",
  },
  input: {
    marginTop: "1em",
    "&::after": {
      borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
  },
  actions: {
    padding: "0 1em 1em 1em",
  },
  button: {
    fontWeight: 500,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  createAccount: {
    cursor: "pointer",
  },
}));
