const URI = `${
  process.env.NODE_ENV !== "production"
    ? process.env.REACT_APP_DEV_URI
    : process.env.REACT_APP_PROD_URI
}/public/create-new-user`;

const isResponseSuccessFull = (resp) => {
  return (
    resp?.status === 204 ||
    resp?.status === 202 ||
    resp?.status === 200 ||
    resp?.ok ||
    false
  );
};

export async function sendFormData({ event, recaptchaRef }) {
  const recaptchaValue = recaptchaRef.current.getValue();

  const resp = await fetch(URI, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ...event,
      recaptchaValue,
    }),
  }).catch(console.error);

  return isResponseSuccessFull(resp);
}
