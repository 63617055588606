import { stringify } from "query-string";
import httpClient from "../httpClient/index";
import { getFileFromServer, toOriginalFile } from "../../utils/getFile";

export default async (type, resource, params) => {
  const URI =
    process.env.NODE_ENV !== "production"
      ? process.env.REACT_APP_DEV_URI
      : process.env.REACT_APP_PROD_URI;

  switch (type) {
    case "GET_LIST":
    case "GET_MANY_REFERENCES":
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filter: JSON.stringify(params.filter),
      };

      return httpClient(`${URI}/${resource}/many?${stringify(query)}`).then(
        ({ json }) => ({
          data:
            json?.data?.map?.((record) => {
              const id = record._id;
              delete record._id;
              return {
                id,
                ...record,
                files: record.files?.map?.((file) => ({
                  ...file,
                  filename: getFileFromServer(file),
                })),
              };
            }) || [],
          total: json?.total || 0,
        })
      );

    case "GET_MANY":
      const qry = {
        filter: JSON.stringify({ id: params.ids }),
      };

      return httpClient(`${URI}/${resource}/many?${stringify(qry)}`).then(
        ({ json }) => ({
          data:
            json?.data?.map?.((record) => {
              const id = record._id;
              delete record._id;
              return {
                id,
                ...record,
                files: record.files?.map?.((file) => ({
                  ...file,
                  filename: getFileFromServer(file),
                })),
              };
            }) || [],
          total: json?.total,
        })
      );

    case "GET_ONE":
      return httpClient(`${URI}/${resource}/${params.id}`).then(({ json }) => {
        return {
          data: {
            ...json.data,
            files: json.data.files?.map?.((file) => ({
              ...file,
              filename: getFileFromServer(file),
            })),
          },
          id: json.data._id,
        };
      });

    case "UPDATE":
      return (async () => {
        const formData = new FormData();

        for (const param in params.data) {
          if (param === "files") {
            params.data[param].forEach((file) => {
              if (file.rawFile) {
                formData.append("files", file.rawFile);
              } else {
                const obj = {
                  ...file,
                  filename: toOriginalFile(file),
                };

                formData.append("files", JSON.stringify(obj));
              }
            });
            continue;
          }

          formData.append(param, params.data[param]);
        }

        return httpClient(`${URI}/${resource}/${params.id}`, {
          method: "PUT",
          body: formData,
        }).then(({ json }) => ({ data: json.data }));
      })();

    case "UPDATE_MANY":
      return (async () => {
        const formData = new FormData();

        for (const param in params.data) {
          if (param === "files") {
            params.data[param].forEach((file) => {
              formData.append("files", file.rawFile);
            });
            continue;
          }

          formData.append(param, params.data[param]);
        }

        return httpClient(`${URI}/${resource}/many/${params.id}`, {
          method: "PUT",
          body: formData,
        }).then(({ json }) => ({ data: json.data }));
      })();

    case "DELETE":
      return httpClient(`${URI}/${resource}/${params.id}`, {
        method: "DELETE",
      }).then(({ json }) => {
        return { data: json.data };
      });

    case "DELETE_MANY":
      const param = {
        filter: JSON.stringify(params.ids),
      };

      return httpClient(
        `${URI}/${resource}/many/${params.id}?${stringify(param)}`,
        {
          method: "DELETE",
          body: JSON.stringify(params),
        }
      ).then(({ json }) => ({ data: json.data }));

    case "CREATE":
      return (async () => {
        const formData = new FormData();

        for (const param in params.data) {
          if (param === "files" && params.data[param].length) {
            params.data[param].forEach((file) => {
              formData.append("files", file.rawFile);
            });
            continue;
          }

          formData.append(param, params.data[param]);
        }

        return httpClient(`${URI}/${resource}`, {
          method: "POST",
          body: formData,
        }).then(({ json }) => {
          return {
            data: { ...params.data, id: json.data._id },
          };
        });
      })();

    default:
  }
};
