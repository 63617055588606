import userProvider from "./users";
import propertyProvider from "./property";
import layoutProvider from "./layout";
import defaultProvider from "./default";

const dataProviders = [
  { dataProvider: userProvider, resources: ["users"] },
  { dataProvider: layoutProvider, resources: ["layouts"] },
  { dataProvider: propertyProvider, resources: ["properties"] },
  {
    dataProvider: defaultProvider,
    resources: ["availability", "reservations", "clients", "portals"],
  },
];

export default async (type, resource, params) => {
  const dataProviderMapping = dataProviders.find((dp) =>
    dp.resources.includes(resource)
  );
  return dataProviderMapping.dataProvider(type, resource, params);
};
