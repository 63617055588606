import React, { createRef, useState } from "react";
import { Field, withTypes } from "react-final-form";
import { useLocation } from "react-router-dom";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CircularProgress,
  Grid,
  TextField,
} from "@material-ui/core";
import { Notification, useNotify, useLogin } from "react-admin";
import ReCAPTCHA from "react-google-recaptcha";

import useStyles from "./login.styles";
import { sendFormData } from "./createNewUser.util";
import withRoot from "../theme/withRoot";

const renderInput = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

function CreateUser() {
  const classes = useStyles();
  const recaptchaRef = createRef();
  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  const login = useLogin();
  const location = useLocation();
  const { Form } = withTypes();

  const handleSubmit = async (event) => {
    if (event.password !== event.passwordCheck) {
      notify("Wachtwoorden zijn niet aan elkaar gelijk!", "warning");
      throw new Error("Wachtwoorden zijn niet aan elkaar gelijk!");
    }

    setLoading(true);

    const created = await sendFormData({ event, recaptchaRef });

    login(
      { username: event.username, password: event.password },
      location.state ? location.state.nextPathname : "/"
    ).catch((error) => {
      setLoading(false);
      notify(
        typeof error === "string"
          ? error
          : typeof error === "undefined" || !error.message
          ? "ra.auth.sign_in_error"
          : error.message,
        "warning"
      );
    });
  };

  return (
    <Form
      onSubmit={handleSubmit}
      //   validate={validate}
      render={({ handleSubmit }) => (
          <Grid
            container
            className={classes.root}
            justify="center"
            alignContent="center"
            alignItems="center"
          >
            <Grid item xs={12} sm={6} md={4} xl={3}>
            <form onSubmit={handleSubmit} noValidate>
              <Card className={classes.card}>
                <div className={classes.avatar}>
                  <Avatar
                    className={classes.icon}
                    sizes={80}
                    src={require("../../assets/logo.svg")}
                  />
                </div>
                <div className={classes.form}>
                  <div className={classes.input}>
                    <Field
                      autoFocus
                      name="name"
                      component={renderInput}
                      label="Naam"
                      disabled={loading}
                      required
                    />
                  </div>
                  <div className={classes.input}>
                    <Field
                      autoFocus
                      name="email"
                      component={renderInput}
                      label="email"
                      disabled={loading}
                      required
                    />
                  </div>
                  <div className={classes.input}>
                    <Field
                      autoFocus
                      name="username"
                      component={renderInput}
                      label="Gebruikersnaam"
                      disabled={loading}
                      required
                    />
                  </div>
                  <div className={classes.input}>
                    <Field
                      name="password"
                      component={renderInput}
                      label={"Wachtwoord"}
                      type="password"
                      disabled={loading}
                      required
                    />
                  </div>
                  <div className={classes.input}>
                    <Field
                      name="passwordCheck"
                      component={renderInput}
                      label={"Herhaal wachtwoord"}
                      type="password"
                      disabled={loading}
                      required
                    />
                  </div>
                  <div className={classes.input}>
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey="6LcZVo0aAAAAAAuVxJxGazVmV7eer7btPk25SOk5"
                    />
                  </div>
                </div>
                <CardActions className={classes.actions}>
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={loading}
                    fullWidth
                    className={classes.button}
                  >
                    {loading && <CircularProgress size={25} thickness={2} />}
                    Aanmaken
                  </Button>
                </CardActions>
              </Card>
              <Notification />
              </form>
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={3}>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={loading}
                fullWidth
                className={classes.button}
              >
                Terug
              </Button>
            </Grid>
          </Grid>
      )}
    />
  );
}

export default withRoot(CreateUser);
