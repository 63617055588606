import * as React from "react";
import {
  Create,
  Edit,
  List,
  Datagrid,
  BooleanInput,
  BooleanField,
  ReferenceField,
  TextField,
  DeleteButton,
  EditButton,
  TextInput,
  SimpleForm,
  required,
  Show,
  SimpleShowLayout,
  Filter,
  useTranslate,
  SelectInput,
  ReferenceInput,
} from "react-admin";
import { makeStyles, Chip } from "@material-ui/core";

const useStyles = makeStyles({
  image: {
    height: "2rem",
    width: "2rem",
    borderRadius: "100%",
  },
});

const useQuickFilterStyles = makeStyles((theme) => ({
  chip: {
    marginBottom: theme.spacing(1),
  },
}));
const QuickFilter = ({ label }) => {
  const translate = useTranslate();
  const classes = useQuickFilterStyles();
  return <Chip className={classes.chip} label={translate(label)} />;
};

const PostFilter = (props) => (
  <Filter {...props}>
    <QuickFilter source="isActive" label="isActive" defaultValue={true} />
  </Filter>
);

export const PortalList = (props) => {
  const classes = useStyles();

  if (props.permissions !== "superuser") {
    return null;
  }

  return (
    <List {...props} filters={<PostFilter />}>
      <Datagrid>
        <TextField source="name" label="Naam" />
        <TextField source="url" label="Domeinnaam" />
        <TextField source="iban" label="IBAN" />
        <ReferenceField
          link="show"
          label="Eigenaar"
          source="ownerId"
          reference="users"
        >
          <TextField source="name" />
        </ReferenceField>
        <BooleanField source="isActive" label="Is actief" />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};

export const PortalShow = (props) => {
  const classes = useStyles();

  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="name" label="Naam" />
        <TextField source="url" label="Domeinnaam" />
        <TextField source="iban" label="IBAN" />
        <ReferenceField
          link="show"
          label="Eigenaar"
          source="ownerId"
          reference="users"
        >
          <TextField source="name" />
        </ReferenceField>
        <BooleanField source="isActive" label="Is actief" />
      </SimpleShowLayout>
    </Show>
  );
};

export const PortalEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" label="Naam" validate={[required()]} />
      <TextInput
        source="url"
        label="Domein"
        type="url"
        validate={[required()]}
      />
      <ReferenceInput
        label="Eigenaar"
        source="ownerId"
        reference="users"
        link="show"
        validate={[required()]}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput label="IBAN" source="iban" validate={[required()]} />
      <BooleanInput initialValue={false} source="isActive" label="Is actief" />
    </SimpleForm>
  </Edit>
);

export const PortalCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" label="Naam" validate={[required()]} />
      <TextInput
        source="url"
        label="Domein"
        type="url"
        validate={[required()]}
      />
      <ReferenceInput
        label="Eigenaar"
        source="ownerId"
        reference="users"
        link="show"
        validate={[required()]}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput label="IBAN" source="iban" validate={[required()]} />
      <BooleanInput initialValue={false} source="isActive" label="Is actief" />
    </SimpleForm>
  </Create>
);
