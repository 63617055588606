import * as React from "react";
import { useDispatch } from "react-redux";
import {
  AppBar,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  UserMenu,
  useGetOne,
  useGetIdentity,
  LoadingIndicator,
  useTranslate,
  Logout,
  MenuItemLink,
} from "react-admin";
import MenuIcon from "@material-ui/icons/Menu";
import SettingsIcon from "@material-ui/icons/Settings";
import DashboardIcon from "@material-ui/icons/Dashboard";
import UserIcon from "@material-ui/icons/Person";
import { toggleSidebar } from "ra-core";
import Loader from "../loader";
import HideOnScroll from "./hideOnScroll";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import useStyles from "./navigation.styles";
import withRoot from "../theme/withRoot";

const CustomUserMenu = (props) => {
  const { identity, loading: identityLoading } = useGetIdentity();
  const { data: user, loading: userLoading } = useGetOne(
    "users",
    identity?.sub
  );
  const classes = useStyles();

  if (identityLoading || userLoading || !identity.portalId) {
    return <LoadingIndicator />;
  }

  return (
    <UserMenu
      {...props}
      logout={<Logout {...props} icon={<ExitToAppIcon />} />}
      icon={
        user?.file.filename ? (
          <img src={user.file.filename} className={classes.menuUserIcon} />
        ) : (
          <UserIcon />
        )
      }
      label={user?.name}
      title={user?.name}
    >
      <MenuItemLink
        to="/account"
        primaryText="Account"
        leftIcon={<SettingsIcon />}
      />
      <MenuItemLink
        to="/portal"
        primaryText="Portal"
        leftIcon={<DashboardIcon />}
      />
    </UserMenu>
  );
};

function NavBar(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const translate = useTranslate();

  return (
    <HideOnScroll>
      <AppBar
        className={classes.navbar}
        {...props}
        elevation={1}
        userMenu={<CustomUserMenu />}
      >
        <Toolbar>
          <Tooltip
            title={translate(
              props.open ? "ra.action.close_menu" : "ra.action.open_menu",
              {
                _: "Open/Close menu",
              }
            )}
            enterDelay={500}
          >
            <IconButton
              color="inherit"
              onClick={() => dispatch(toggleSidebar())}
              className={classes.menuButton}
            >
              <MenuIcon
                classes={{
                  root: props.open
                    ? classes.menuButtonIconOpen
                    : classes.menuButtonIconClosed,
                }}
              />
            </IconButton>
          </Tooltip>
          <Typography
            variant="h6"
            color="inherit"
            className={classes.title}
            id="react-admin-title"
          />
          <img
            className={classes.logo}
            src={require("../../assets/logo.svg")}
            alt="logo"
          />
          {/* Vakantiehuizen portal */}
          <span className={classes.spacer} />
          <Loader />
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  );
}

export default withRoot(NavBar);
