import React from "react";
import {
  Edit,
  TextInput,
  SimpleForm,
  required,
  useGetIdentity,
  LoadingIndicator,
  useGetOne,
  Toolbar,
  SaveButton,
} from "react-admin";

const UserEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const ProfileEdit = ({ staticContext, ...props }) => {
  const { identity, loading: identityLoading } = useGetIdentity();
  const { data: portal, loading: portaslLoading } = useGetOne(
    "portals",
    identity?.portalId
  );

  if (identityLoading || portaslLoading || !identity.portalId) {
    return <LoadingIndicator />;
  }

  return (
    <Edit
      /*
                As this component isn't loaded from a route generated by a <Resource>,
                I have to provide the id myself.
                As there is only one config for the current user, I decided to
                hard-code it here
            */
      id={identity.portalId}
      /*
                For the same reason, I need to provide the resource and basePath props
                which are required by the Edit component
            */
      resource="portals"
      basePath="portals"
      redirect={false}
      title="Account instellingen"
      {...props}
    >
      <SimpleForm toolbar={<UserEditToolbar />}>
        <TextInput source="name" label="Naam" validate={[required()]} />
        <TextInput
          source="url"
          label="Domein"
          type="url"
          validate={[required()]}
        />
        <TextInput label="IBAN" source="iban" validate={[required()]} />
      </SimpleForm>
    </Edit>
  );
};

export default ProfileEdit;
