import * as React from "react";
import {
  Create,
  Edit,
  List,
  Datagrid,
  BooleanInput,
  BooleanField,
  DateField,
  NumberInput,
  NumberField,
  TextField,
  DeleteButton,
  EditButton,
  SimpleForm,
  required,
  Show,
  SimpleShowLayout,
  DateInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  DateTimeInput,
} from "react-admin";

export const AvailabilityList = (props) => {
  return (
    <List {...props}>
      <Datagrid>
        <ReferenceField
          link="show"
          label="Vakantiewoning"
          source="property"
          reference="properties"
        >
          <TextField source="title" />
        </ReferenceField>
        <DateField source="startDate" label="Van" showTime />
        <DateField source="endDate" label="Tot" showTime />
        <NumberField
          source="price"
          label="Prijs"
          options={{ style: "currency", currency: "EUR" }}
        />
        <BooleanField source="isAvailable" label="Beschikbaar" />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};

export const AvailabilityShow = (props) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <ReferenceField
          link="show"
          label="Vakantiewoning"
          source="property"
          reference="properties"
          validate={[required()]}
        >
          <TextField source="title" />
        </ReferenceField>
        <DateField source="startDate" label="Van" showTime />
        <DateField source="endDate" label="Tot" showTime />
        <NumberField
          source="price"
          label="Prijs"
          options={{ style: "currency", currency: "EUR" }}
        />

        <BooleanField source="isAvailable" label="Beschikbaar" />
      </SimpleShowLayout>
    </Show>
  );
};

export const AvailabilityEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <ReferenceInput
        label="Vakantiewoning"
        source="property"
        reference="properties"
        link="show"
        validate={[required()]}
      >
        <SelectInput optionText="title" />
      </ReferenceInput>
      <DateTimeInput source="startDate" label="Van" validate={[required()]} />
      <DateTimeInput label="Tot" source="endDate" validate={[required()]} />
      <NumberInput label="Prijs" source="price" />
      <BooleanInput source="isAvailable" label="Beschikbaar" />
    </SimpleForm>
  </Edit>
);

export const AvailabilityCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput
        label="Vakantiewoning"
        source="property"
        reference="properties"
        link="show"
        validate={[required()]}
      >
        <SelectInput optionText="title" />
      </ReferenceInput>
      <DateTimeInput source="startDate" label="Van" validate={[required()]} />
      <DateTimeInput label="Tot" source="endDate" validate={[required()]} />
      <NumberInput label="Prijs" source="price" />
      <BooleanInput
        source="isAvailable"
        label="Beschikbaar"
        initialValue={true}
        validate={[required()]}
      />
    </SimpleForm>
  </Create>
);
