import React from "react";
import { Grid, Link, Typography } from "@material-ui/core";
import useStyles from "./footer.styles";
import { withRoot } from "..";

function Footer() {
  const classes = useStyles();

  return (
    <Grid container spacing={10} className={classes.container} justify="center">
      <Grid
        className={classes.noPadding}
        item
        xs={12}
        sm={12}
        md={4}
        justify="center"
        alignItems="center"
      >
        <Typography
          variant="subtitle1"
          component="h2"
          gutterBottom
          align="center"
        >
          <Link
            className={classes.link}
            href={"https://datails.nl"}
            target="_blank"
          >
            Powered by Datails
          </Link>
        </Typography>
      </Grid>
    </Grid>
  );
}

export default withRoot(Footer);
