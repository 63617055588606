import * as React from "react";
import {
  Create,
  Edit,
  List,
  Datagrid,
  TextField,
  DeleteButton,
  EditButton,
  TextInput,
  SimpleForm,
  required,
  Show,
  SimpleShowLayout,
  NumberField,
  NumberInput,
  TabbedForm,
  FormTab,
} from "react-admin";

export const ClientList = (props) => {
  return (
    <List {...props}>
      <Datagrid>
        <TextField label="Voornaam" source="firstname" />
        <TextField label="Achternaam" source="lastname" />
        <NumberField label="Huisnummer" source="housenumber" />
        <TextField label="Postcode" source="zipcode" />
        <TextField label="Email" source="email" />
        <TextField label="Telefoon" source="telephone" />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};

export const ClientShow = (props) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField label="Voornaam" source="firstname" />
        <TextField label="Achternaam" source="lastname" />
        <TextField label="Tussenvoegsel" source="insertion" />
        <TextField label="Straat" source="street" />
        <TextField label="Plaatsnaam" source="city" />
        <NumberField label="Huisnummer" source="housenumber" />
        <TextField label="Huisnummerextensie" source="housenumberextension" />
        <TextField label="Postcode" source="zipcode" />
        <TextField label="Email" source="email" />
        <TextField label="Telefoon" source="telephone" />
      </SimpleShowLayout>
    </Show>
  );
};

export const ClientEdit = (props) => (
  <Edit {...props}>
    <TabbedForm>
      <FormTab label="Algemeen">
        <TextInput
          label="Voornaam"
          source="firstname"
          validate={[required()]}
        />
        <TextInput
          label="Achternaam"
          source="lastname"
          validate={[required()]}
        />
        <TextInput label="Tussenvoegsel" source="insertion" />
      </FormTab>
      <FormTab label="Adres">
        <TextInput label="Straat" source="street" validate={[required()]} />
        <TextInput label="Plaatsnaam" source="city" validate={[required()]} />
        <NumberInput
          label="Huisnummer"
          source="housenumber"
          validate={[required()]}
        />
        <TextInput label="Huisnummerextensie" source="housenumberextension" />
        <TextInput label="Postcode" source="zipcode" validate={[required()]} />
      </FormTab>
      <FormTab label="Contact">
        <TextInput label="Email" source="email" validate={[required()]} />
        <TextInput label="Telefoon" source="telephone" />
      </FormTab>
    </TabbedForm>
  </Edit>
);

export const ClientCreate = (props) => (
  <Create {...props}>
    <TabbedForm>
      <FormTab label="Algemeen">
        <TextInput
          label="Voornaam"
          source="firstname"
          validate={[required()]}
        />
        <TextInput
          label="Achternaam"
          source="lastname"
          validate={[required()]}
        />
        <TextInput label="Tussenvoegsel" source="insertion" />
      </FormTab>
      <FormTab label="Adres">
        <TextInput label="Straat" source="street" validate={[required()]} />
        <TextInput label="Plaatsnaam" source="city" validate={[required()]} />
        <NumberInput
          label="Huisnummer"
          source="housenumber"
          validate={[required()]}
        />
        <TextInput label="Huisnummerextensie" source="housenumberextension" />
        <TextInput label="Postcode" source="zipcode" validate={[required()]} />
      </FormTab>
      <FormTab label="Contact">
        <TextInput label="Email" source="email" validate={[required()]} />
        <TextInput label="Telefoon" source="telephone" />
      </FormTab>
    </TabbedForm>
  </Create>
);
