import * as React from "react";
import { Layout, Sidebar } from "react-admin";
import Footer from "./footer";
import NavBar from "./navigation";

const CustomSidebar = (props) => <Sidebar {...props} size={200} />;

export default (props) => {
  return (
    <>
      <Layout {...props} appBar={NavBar} sidebar={CustomSidebar} />
      <Footer />
    </>
  );
};
