import { stringify } from "query-string";
import httpClient from "../httpClient/index";
import { getFileFromServer, toOriginalFile } from "../../utils/getFile";

export default async (type, resource, params) => {
  const URI =
    process.env.NODE_ENV !== "production"
      ? process.env.REACT_APP_DEV_URI
      : process.env.REACT_APP_PROD_URI;

  switch (type) {
    case "GET_LIST":
    case "GET_MANY_REFERENCES":
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filter: JSON.stringify(params.filter),
      };

      return httpClient(`${URI}/${resource}/many?${stringify(query)}`).then(
        ({ json }) => ({
          data:
            json?.data?.map?.((record) => {
              const id = record._id;
              delete record._id;
              return {
                id,
                ...record,
                file: {
                  ...record.file,
                  filename: getFileFromServer(record.file),
                },
              };
            }) || [],
          total: json?.total || 0,
        })
      );

    case "GET_MANY":
      const qry = {
        filter: JSON.stringify({ id: params.ids }),
      };

      return httpClient(`${URI}/${resource}/many?${stringify(qry)}`).then(
        ({ json }) => ({
          data:
            json?.data?.map?.((record) => {
              const id = record._id;
              delete record._id;
              return {
                id,
                ...record,
                file: {
                  ...record.file,
                  filename: getFileFromServer(record.file),
                },
              };
            }) || [],
          total: json?.total,
        })
      );

    case "GET_ONE":
      // if no params present, skip it to prevent
      // unnecesary backend calls
      if (!params.id) {
        return {
          data: {
            id: null,
          },
        };
      }

      return httpClient(`${URI}/${resource}/${params.id}`).then(({ json }) => ({
        data: {
          ...json.data,
          file: {
            ...json.data.file,
            filename: getFileFromServer(json.data.file),
          },
        },
        id: json.data._id,
      }));

    case "UPDATE":
      return (async () => {
        const formData = new FormData();

        for (const param in params.data) {
          if (param === "file") {
            if (params.data[param].rawFile) {
              formData.append("file", params.data[param].rawFile);
            } else {
              const obj = {
                ...params.data[param],
                filename: toOriginalFile(params.data[param]),
              };

              formData.append("file", JSON.stringify(obj));
            }

            continue;
          }

          if (param === "owner") {
            params.data[param].forEach((owner) => {
              formData.append("owner", owner);
            });
            continue;
          }

          formData.append(param, params.data[param]);
        }

        return httpClient(`${URI}/${resource}/${params.id}`, {
          method: "PUT",
          body: formData,
        }).then(({ json }) => ({ data: json.data }));
      })();

    case "UPDATE_MANY":
      return (async () => {
        const formData = new FormData();

        for (const param in params.data) {
          if (param === "file") {
            formData.append("file", params.data[param].rawFile);
            continue;
          }

          if (param === "owner") {
            params.data[param].forEach((owner) => {
              formData.append("owner", owner);
            });
            continue;
          }

          formData.append(param, params.data[param]);
        }

        return httpClient(`${URI}/${resource}/many/${params.id}`, {
          method: "PUT",
          body: formData,
        }).then(({ json }) => ({ data: json.data }));
      })();

    case "DELETE":
      return httpClient(`${URI}/${resource}/${params.id}`, {
        method: "DELETE",
      }).then(({ json }) => {
        return { data: json.data };
      });

    case "DELETE_MANY":
      const param = {
        filter: JSON.stringify(params.ids),
      };

      return httpClient(
        `${URI}/${resource}/many/${params.id}?${stringify(param)}`,
        {
          method: "DELETE",
          body: JSON.stringify(params),
        }
      ).then(({ json }) => ({ data: json.data }));

    case "CREATE":
      return (async () => {
        const formData = new FormData();

        for (const param in params.data) {
          if (param === "file") {
            formData.append("file", params.data[param].rawFile);
            continue;
          }

          if (param === "owner") {
            params.data[param].forEach((owner) => {
              formData.append("owner", owner);
            });
            continue;
          }

          formData.append(param, params.data[param]);
        }

        return httpClient(`${URI}/${resource}`, {
          method: "POST",
          body: formData,
        }).then(({ json }) => {
          return {
            data: { ...params.data, id: json.data._id },
          };
        });
      })();

    default:
  }
};
