import * as React from "react";
import {
  Create,
  Edit,
  List,
  Datagrid,
  BooleanInput,
  BooleanField,
  DateField,
  NumberInput,
  NumberField,
  TextField,
  DeleteButton,
  EditButton,
  SimpleForm,
  required,
  Show,
  SimpleShowLayout,
  SelectField,
  SelectInput,
  ReferenceInput,
  ReferenceField,
  DateTimeInput,
} from "react-admin";

export const ReservationList = (props) => {
  return (
    <List {...props}>
      <Datagrid>
        <ReferenceField
          label="Reserveerder"
          source="client"
          reference="clients"
          link="show"
        >
          <TextField source="email" />
        </ReferenceField>
        <ReferenceField
          link="show"
          label="Vakantiewoning"
          source="property"
          reference="properties"
        >
          <TextField source="title" />
        </ReferenceField>
        <NumberField label="Prijs" source="price" />
        <DateField label="Van" source="startDate" showTime />
        <DateField label="Tot" source="endDate" showTime />
        <SelectField
          label="Status"
          source="status"
          choices={[
            { id: "aanvraag", name: "aanvraag" },
            { id: "geaccepteerd", name: "geaccepteerd" },
            { id: "geannuleerd", name: "geannuleerd" },
          ]}
        />
        <BooleanField label="Is betaald" source="isPaid" />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};

export const ReservationShow = (props) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <ReferenceField
          label="Reserveerder"
          source="client"
          reference="clients"
          link="show"
        >
          <TextField source="email" />
        </ReferenceField>
        <ReferenceField
          link="show"
          label="Vakantiewoning"
          source="property"
          reference="properties"
        >
          <TextField source="title" />
        </ReferenceField>
        <NumberField label="Prijs" source="price" />
        <NumberField label="Volwassenen" source="adults" />
        <NumberField label="Kinderen" source="children" />
        <DateField label="Van" source="startDate" showTime />
        <DateField label="Tot" source="endDate" showTime />
        <SelectField
          label="Status"
          source="status"
          choices={[
            { id: "aanvraag", name: "aanvraag" },
            { id: "geaccepteerd", name: "geaccepteerd" },
            { id: "geannuleerd", name: "geannuleerd" },
          ]}
        />
        <BooleanField label="Is betaald" source="isPaid" />
      </SimpleShowLayout>
    </Show>
  );
};

export const ReservationEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <ReferenceInput
        label="Reserveerder"
        source="client"
        reference="clients"
        link="show"
        validate={[required()]}
      >
        <SelectInput optionText="email" />
      </ReferenceInput>
      <ReferenceInput
        label="Vakantiewoning"
        source="property"
        reference="properties"
        link="show"
        validate={[required()]}
      >
        <SelectInput optionText="title" />
      </ReferenceInput>
      <NumberInput label="Prijs" source="price" validate={[required()]} />
      <NumberInput
        label="Volwassenen"
        source="adults"
        validate={[required()]}
      />
      <NumberInput label="Kinderen" source="children" validate={[required()]} />
      <DateTimeInput label="Van" source="startDate" validate={[required()]} />
      <DateTimeInput label="Tot" source="endDate" validate={[required()]} />
      <SelectInput
        label="Status"
        source="status"
        choices={[
          { id: "aanvraag", name: "aanvraag" },
          { id: "geaccepteerd", name: "geaccepteerd" },
          { id: "geannuleerd", name: "geannuleerd" },
        ]}
        validate={[required()]}
      />
      <BooleanInput label="Is betaald" source="isPaid" initialValue={false} />
    </SimpleForm>
  </Edit>
);

export const ReservationCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput
        label="Reserveerder"
        source="client"
        reference="clients"
        link="show"
        validate={[required()]}
      >
        <SelectInput optionText="email" />
      </ReferenceInput>
      <ReferenceInput
        label="Vakantiewoning"
        source="property"
        reference="properties"
        link="show"
        validate={[required()]}
      >
        <SelectInput optionText="title" />
      </ReferenceInput>
      <NumberInput label="Prijs" source="price" validate={[required()]} />
      <NumberInput
        label="Volwassenen"
        source="adults"
        validate={[required()]}
      />
      <NumberInput label="Kinderen" source="children" validate={[required()]} />
      <DateTimeInput label="Van" source="startDate" validate={[required()]} />
      <DateTimeInput label="Tot" source="endDate" validate={[required()]} />
      <SelectInput
        label="Status"
        source="status"
        choices={[
          { id: "aanvraag", name: "aanvraag" },
          { id: "geaccepteerd", name: "geaccepteerd" },
          { id: "geannuleerd", name: "geannuleerd" },
        ]}
        validate={[required()]}
      />
      <BooleanInput label="Is betaald" source="isPaid" initialValue={false} />
    </SimpleForm>
  </Create>
);
