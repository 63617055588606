import decodeJwt from "jwt-decode";

const authProvider = {
  login: async (params) => {
    const resp = await fetch(
      `${
        process.env.NODE_ENV !== "production"
          ? process.env.REACT_APP_DEV_URI
          : process.env.REACT_APP_PROD_URI
      }/auth/login`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(params),
      }
    );

    if (resp.status > 204 && resp.status < 200) {
      throw new Error(resp.statusText);
    }

    if (resp.status === 401 || resp.status === 403) {
      throw new Error(resp.statusText);
    }

    const {
      data: { token },
    } = await resp.json();

    sessionStorage.setItem("token", token);

    return Promise.resolve();
  },
  logout: () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("user");
    return Promise.resolve();
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () =>
  sessionStorage.getItem("token") ? Promise.resolve() : Promise.reject(),
  getPermissions: () => {
    const role = decodeJwt(sessionStorage.getItem("token")).role;
    return role ? Promise.resolve(role) : Promise.reject();
  },
  getIdentity: () =>
  sessionStorage.getItem("token")
      ? Promise.resolve(decodeJwt(sessionStorage.getItem("token")))
      : Promise.reject(),
};

export default authProvider;
