import React from "react";
import {
  Edit,
  Create,
  SimpleForm,
  required,
  useGetIdentity,
  LoadingIndicator,
  Toolbar,
  SaveButton,
  ImageInput,
  ImageField,
  TextInput,
  useGetList,
} from "react-admin";
import { ColorInput } from "react-admin-color-input";

const PortalEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const PortalEdit = ({ staticContext, ...props }) => {
  const { identity, loading: identityLoading } = useGetIdentity();
  const { data: portal, loading: portalsLoading } = useGetList(
    "layouts",
    { page: 1, perPage: 1 },
    { field: "published_at", order: "DESC" },
    { portalId: identity?.portalId.find((item) => item) }
  );

  if (identityLoading || portalsLoading || !identity.portalId) {
    return <LoadingIndicator />;
  }

  if (!Object.keys(portal).length) {
    return (
      <Create
        id={identity.portalId}
        resource="layouts"
        basePath="layouts"
        redirect={false}
        title="Portal instellingen"
        {...props}
      >
        <SimpleForm toolbar={<PortalEditToolbar />}>
          <TextInput
            label="Portal"
            source="portalId"
            disabled={true}
            initialValue={identity?.portalId.find((item) => item)}
            hidden={true}
          />
          <ImageInput
            validate={[required()]}
            maxSize={250000}
            source="logo"
            label="Afbeelding"
            accept="image/*"
            placeholder={<p>Plaats hier uw logo.</p>}
          >
            <ImageField source="filename" />
          </ImageInput>
          <ColorInput
            source="colorPalette"
            label="Kleur"
            validate={[required()]}
            picker="Swatches"
          />
        </SimpleForm>
      </Create>
    );
  }

  return (
    <Edit
      id={portal[Object.keys(portal).find((prtl) => prtl)].id}
      resource="layouts"
      basePath="layouts"
      redirect={false}
      title="Portal instellingen"
      {...props}
    >
      <SimpleForm toolbar={<PortalEditToolbar />}>
        <TextInput
          label="Portal"
          source="portalId"
          disabled={true}
          initialValue={identity?.portalId.find((item) => item)}
          hidden={true}
        />
        <ImageInput
          validate={[required()]}
          maxSize={250000}
          source="logo"
          label="Afbeelding"
          accept="image/*"
          placeholder={<p>Plaats hier uw logo.</p>}
        >
          <ImageField source="filename" />
        </ImageInput>
        <ColorInput
          source="colorPalette"
          label="Kleur"
          validate={[required()]}
          picker="Swatches"
        />
      </SimpleForm>
    </Edit>
  );
};

export default PortalEdit;
