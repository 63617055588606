import React from "react";
import {
  Create,
  Edit,
  List,
  Datagrid,
  BooleanInput,
  BooleanField,
  NumberInput,
  NumberField,
  TextField,
  DeleteButton,
  EditButton,
  TextInput,
  required,
  Show,
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  ImageInput,
  ImageField,
  SelectInput,
  SelectField,
  TabbedForm,
  FormTab,
  TabbedShowLayout,
  Tab,
} from "react-admin";
import RichTextInput from "ra-input-rich-text";
import LatLngInput from "./latlongInput";

export const PropertyList = (props) => {
  return (
    <List {...props}>
      <Datagrid>
        <TextField label="Woningnaam" source="title" />
        <NumberField label="Aantal personen" source="persons" />
        <NumberField label="Vierkante meters" source="squareMeters" />
        <NumberField label="Standaard prijs per nacht" source="price" />
        <BooleanField label="Geschikt voor rokers" source="isSmokingProperty" />
        <BooleanField label="Geschikt voor babies" source="isFitForBabies" />
        <BooleanField label="Geschikt voor dieren" source="isFitForAnimals" />
        <BooleanField label="Is verhuurbaar" source="isActive" />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};

export const PropertyShow = (props) => {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Algemeen">
          <TextField label="Woningnaam" source="title" />
          <TextField label="Beschrijving" source="description" />
          <TextField label="Huisregels" source="rules" />
          <TextField label="Stad" source="city" />
          <TextField label="Straat" source="street" />
          <TextField label="Huisnummer" source="housenumer" />
          <TextField
            label="Huisnummerextensie"
            source="housenumber_extension"
          />
          <TextField label="Postcode" source="zipcode" />
          <NumberField label="Prijs per nacht" source="price" />
          <NumberField label="Minimaal aantal nachten" source="minNights" />
          <NumberField label="serviceprijs" source="servicePrice" />
          <NumberField label="Inclusief BTW" source="isVATIncluded" />
          <NumberField
            label="Annuleren tot dag voor boeking"
            source="numberOfDaysBeforeCancel"
          />
          <TextField label="Inchecken" source="checkin" />
          <TextField label="Uitchecken" source="checkout" />
        </Tab>
        <Tab label="Woning Eigenschappen">
          <NumberField label="Aantal personen" source="persons" />
          <NumberField label="Vierkante meters" source="squareMeters" />
          <NumberField label="Kamers" source="chambers" />
          <NumberField label="Bedden" source="beds" />
          <NumberField label="Badkamers" source="bathrooms" />
          <SelectField
            label="Parkeren"
            source="parking"
            choices={[
              {
                id: "Gratis parkeren op het terrein",
                name: "Gratis parkeren op het terrein",
              },
              {
                id: "Betaald parkeren op het terrein",
                name: "Betaald parkeren op het terrein",
              },
              {
                id: "Gratis parkeren buiten het terrein",
                name: "Gratis parkeren buiten het terrein",
              },
              {
                id: "Betaald parkeren buiten het terrein",
                name: "Betaald parkeren buiten het terrein",
              },
              {
                id: "Geen parkeermogelijkheden",
                name: "Geen parkeermogelijkheden",
              },
            ]}
          />
          <BooleanField label="Eigen ingang" source="hasOwnEntrance" />
          <BooleanField
            label="Geschikt voor rokers"
            source="isSmokingProperty"
          />
          <BooleanField label="Geschikt voor babies" source="isFitForBabies" />
          <BooleanField label="Geschikt voor dieren" source="isFitForAnimals" />
          <BooleanField
            label="Schoonmaken voor vertrek"
            source="isCleaningBeforeLeave"
          />
          <BooleanField label="Is verhuurbaar" source="isActive" />
        </Tab>
        <Tab label="Voorzieningen">
          <BooleanField
            label="Minder-valide vriendelijk"
            source="isHandicapped"
          />
          <BooleanField label="Televisie" source="hasTelevision" />
          <BooleanField label="Warm water" source="hasWarmWater" />
          <BooleanField label="Verwarming" source="hasHeating" />
          <BooleanField label="Houtskachel/openhaard" source="hasFireplace" />
          <BooleanField label="WiFi" source="hasWifi" />
          <BooleanField label="Oven" source="hasStove" />
          <BooleanField label="Borden en bestek" source="hasPlatesAndCutlery" />
          <BooleanField label="Magnetron" source="hasMicrowave" />
          <BooleanField label="Koffiezetapparaat" source="hasCoffeeMachine" />
          <BooleanField label="Kookgerei" source="hasCookingSupplies" />
          <BooleanField label="Keuken" source="hasKitchen" />
          <BooleanField label="Koelkast" source="hasFridge" />
          <BooleanField label="Kledinghangers" source="hasClothesHangers" />
          <BooleanField label="Beddengoed" source="hasBedding" />
          <BooleanField label="Douchegel" source="hasShowerGel" />
          <BooleanField label="Shampoo" source="hasShampoo" />
          <BooleanField label="Tuin" source="hasGarden" />
          <BooleanField label="Schuur" source="hasBarn" />
          <BooleanField label="Rookmelder" source="hasSmokeAlarm" />
          <BooleanField
            label="Koolmonoxidemelder"
            source="hasCarbonMonoxideDetector"
          />
          <BooleanField label="Fohn" source="hasHairDryer" />
          <BooleanField label="Airconditioner" source="hasAirconditioning" />
          <BooleanField label="Wasmachine" source="hasWachingMachine" />
        </Tab>
        <Tab label="Foto's">
          <ImageField source="files" src="filename" label="Afbeelding" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export const PropertyEdit = (props) => {
  return (
    <Edit {...props}>
      <TabbedForm>
        <FormTab label="Algemeen">
          <TextInput
            label="Woningnaam"
            source="title"
            validate={[required()]}
          />
          <RichTextInput
            label="Beschrijving"
            source="description"
            validate={[required()]}
          />
          <RichTextInput
            label="Huisregels"
            source="rules"
            validate={[required()]}
          />
          <NumberInput
            label="Standaard prijs per nacht"
            source="price"
            validate={[required()]}
          />
          <NumberInput
            label="Minimaal aantal nachten"
            source="minNights"
            validate={[required()]}
          />
          <NumberInput
            label="Serviceprijs"
            source="servicePrice"
            validate={[required()]}
          />
          <BooleanInput
            label="Inclusief BTW"
            source="isVATIncluded"
            validate={[required()]}
            initialValue={false}
          />
          <LatLngInput
            label="Locatie"
            source="position"
            validate={[required()]}
          />
          <TextInput label="Stad" source="city" validate={[required()]} />
          <TextInput label="Straat" source="street" validate={[required()]} />
          <TextInput
            label="Huisnummer"
            source="housenumber"
            validate={[required()]}
          />
          <TextInput
            label="Huisnummer extensie"
            source="housenumber_extension"
          />
          <TextInput
            label="Postcode"
            source="zipcode"
            validate={[required()]}
          />
          <NumberInput
            label="Annuleren tot dag voor boeking"
            source="numberOfDaysBeforeCancel"
            validate={[required()]}
          />
          <TextInput
            label="Inchecken"
            source="checkin"
            validate={[required()]}
          />
          <TextInput
            label="Uitchecken"
            source="checkout"
            validate={[required()]}
          />
        </FormTab>
        <FormTab label="Woning eigenschappen">
          <NumberInput
            label="Aantal personen"
            source="persons"
            validate={[required()]}
          />
          <NumberInput
            label="Vierkante meters"
            source="squareMeters"
            validate={[required()]}
          />
          <NumberInput
            label="Kamers"
            source="chambers"
            validate={[required()]}
          />
          <NumberInput label="Bedden" source="beds" validate={[required()]} />
          <NumberInput
            label="Badkamers"
            source="bathrooms"
            validate={[required()]}
          />
          <SelectInput
            label="Parkeren"
            source="parking"
            choices={[
              {
                id: "Gratis parkeren op het terrein",
                name: "Gratis parkeren op het terrein",
              },
              {
                id: "Betaald parkeren op het terrein",
                name: "Betaald parkeren op het terrein",
              },
              {
                id: "Gratis parkeren buiten het terrein",
                name: "Gratis parkeren buiten het terrein",
              },
              {
                id: "Betaald parkeren buiten het terrein",
                name: "Betaald parkeren buiten het terrein",
              },
              {
                id: "Geen parkeermogelijkheden",
                name: "Geen parkeermogelijkheden",
              },
            ]}
            validate={[required()]}
          />
          <BooleanInput
            label="Eigen ingang"
            source="hasOwnEntrance"
            initialValue={false}
          />
          <BooleanInput
            label="Geschikt voor rokers"
            source="isSmokingProperty"
            initialValue={false}
          />
          <BooleanInput
            label="Geschikt voor babies"
            source="isFitForBabies"
            initialValue={false}
          />
          <BooleanInput
            label="Geschikt voor dieren"
            source="isFitForAnimals"
            initialValue={false}
          />
          <BooleanInput
            label="Schoonmaken voor vertrek"
            source="isCleaningBeforeLeave"
            initialValue={true}
          />
          <BooleanInput
            label="Is verhuurbaar"
            source="isActive"
            initialValue={false}
          />
        </FormTab>
        <FormTab label="Voorzieningen">
          <BooleanInput
            label="Minder-valide vriendelijk"
            source="isHandicapped"
            initialValue={false}
          />
          <BooleanInput
            label="Televisie"
            source="hasTelevision"
            initialValue={false}
          />
          <BooleanInput
            label="Warm water"
            source="hasWarmWater"
            initialValue={false}
          />
          <BooleanInput
            label="Verwarming"
            source="hasHeating"
            initialValue={false}
          />
          <BooleanInput
            label="Houtskachel/openhaard"
            source="hasFireplace"
            initialValue={false}
          />
          <BooleanInput label="WiFi" source="hasWifi" initialValue={false} />
          <BooleanInput label="Oven" source="hasStove" initialValue={false} />
          <BooleanInput
            label="Borden en bestek"
            source="hasPlatesAndCutlery"
            initialValue={false}
          />
          <BooleanInput
            label="Magnetron"
            source="hasMicrowave"
            initialValue={false}
          />
          <BooleanInput
            label="Koffiezetapparaat"
            source="hasCoffeeMachine"
            initialValue={false}
          />
          <BooleanInput
            label="Kookgerei"
            source="hasCookingSupplies"
            initialValue={false}
          />
          <BooleanInput
            label="Keuken"
            source="hasKitchen"
            initialValue={false}
          />
          <BooleanInput
            label="Koelkast"
            source="hasFridge"
            initialValue={false}
          />
          <BooleanInput
            label="Kledinghangers"
            source="hasClothesHangers"
            initialValue={false}
          />
          <BooleanInput
            label="Beddengoed"
            source="hasBedding"
            initialValue={false}
          />
          <BooleanInput
            label="Douchegel"
            source="hasShowerGel"
            initialValue={false}
          />
          <BooleanInput
            label="Shampoo"
            source="hasShampoo"
            initialValue={false}
          />
          <BooleanInput label="Tuin" source="hasGarden" initialValue={false} />
          <BooleanInput label="Schuur" source="hasBarn" initialValue={false} />
          <BooleanInput
            label="Rookmelder"
            source="hasSmokeAlarm"
            initialValue={false}
          />
          <BooleanInput
            label="Koolmonoxidemelder"
            source="hasCarbonMonoxideDetector"
            initialValue={false}
          />
          <BooleanInput
            label="Fohn"
            source="hasHairDryer"
            initialValue={false}
          />
          <BooleanInput
            label="Airconditioner"
            source="hasAirconditioning"
            initialValue={false}
          />
          <BooleanInput
            label="Wasmachine"
            source="hasWachingMachine"
            initialValue={false}
          />
        </FormTab>
        <FormTab label="Foto's">
          <ImageInput
            validate={[required()]}
            multiple
            labelMultiple="Upload foto's"
            source="files"
            accept="image/*"
            placeholder={<p>Plaats hier foto's van de woning.</p>}
          >
            <ImageField source="filename" />
          </ImageInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export const PropertyCreate = (props) => {
  return (
    <Create {...props}>
      <TabbedForm>
        <FormTab label="Algemeen">
          <TextInput
            label="Woningnaam"
            source="title"
            validate={[required()]}
          />
          <RichTextInput
            label="Beschrijving"
            source="description"
            validate={[required()]}
          />
          <RichTextInput
            label="Huisregels"
            source="rules"
            validate={[required()]}
          />
          <NumberInput
            label="Standaard prijs per nacht"
            source="price"
            validate={[required()]}
          />
          <NumberInput
            label="Minimaal aantal nachten"
            source="minNights"
            validate={[required()]}
          />
          <NumberInput
            label="Serviceprijs"
            source="servicePrice"
            validate={[required()]}
          />
          <BooleanInput
            label="Inclusief BTW"
            source="isVATIncluded"
            validate={[required()]}
            initialValue={false}
          />
          <LatLngInput
            label="Locatie"
            source="position"
            validate={[required()]}
          />
          <TextInput label="Stad" source="city" validate={[required()]} />
          <TextInput label="Straat" source="street" validate={[required()]} />
          <TextInput
            label="Huisnummer"
            source="housenumber"
            validate={[required()]}
          />
          <TextInput
            label="Huisnummer extensie"
            source="housenumber_extension"
          />
          <TextInput
            label="Postcode"
            source="zipcode"
            validate={[required()]}
          />
          <NumberInput
            label="Annuleren tot dag voor boeking"
            source="numberOfDaysBeforeCancel"
            validate={[required()]}
          />
          <TextInput
            label="Inchecken"
            source="checkin"
            validate={[required()]}
          />
          <TextInput
            label="Uitchecken"
            source="checkout"
            validate={[required()]}
          />
        </FormTab>
        <FormTab label="Woning eigenschappen">
          <NumberInput
            label="Aantal personen"
            source="persons"
            validate={[required()]}
          />
          <NumberInput
            label="Vierkante meters"
            source="squareMeters"
            validate={[required()]}
          />
          <NumberInput
            label="Kamers"
            source="chambers"
            validate={[required()]}
          />
          <NumberInput label="Bedden" source="beds" validate={[required()]} />
          <NumberInput
            label="Badkamers"
            source="bathrooms"
            validate={[required()]}
          />
          <SelectInput
            label="Parkeren"
            source="parking"
            choices={[
              {
                id: "Gratis parkeren op het terrein",
                name: "Gratis parkeren op het terrein",
              },
              {
                id: "Betaald parkeren op het terrein",
                name: "Betaald parkeren op het terrein",
              },
              {
                id: "Gratis parkeren buiten het terrein",
                name: "Gratis parkeren buiten het terrein",
              },
              {
                id: "Betaald parkeren buiten het terrein",
                name: "Betaald parkeren buiten het terrein",
              },
              {
                id: "Geen parkeermogelijkheden",
                name: "Geen parkeermogelijkheden",
              },
            ]}
            validate={[required()]}
          />
          <BooleanInput
            label="Eigen ingang"
            source="hasOwnEntrance"
            initialValue={false}
          />
          <BooleanInput
            label="Geschikt voor rokers"
            source="isSmokingProperty"
            initialValue={false}
          />
          <BooleanInput
            label="Geschikt voor babies"
            source="isFitForBabies"
            initialValue={false}
          />
          <BooleanInput
            label="Geschikt voor dieren"
            source="isFitForAnimals"
            initialValue={false}
          />
          <BooleanInput
            label="Schoonmaken voor vertrek"
            source="isCleaningBeforeLeave"
            initialValue={true}
          />
          <BooleanInput
            label="Is verhuurbaar"
            source="isActive"
            initialValue={false}
          />
        </FormTab>
        <FormTab label="Voorzieningen">
          <BooleanInput
            label="Minder-valide vriendelijk"
            source="isHandicapped"
            initialValue={false}
          />
          <BooleanInput
            label="Televisie"
            source="hasTelevision"
            initialValue={false}
          />
          <BooleanInput
            label="Warm water"
            source="hasWarmWater"
            initialValue={false}
          />
          <BooleanInput
            label="Verwarming"
            source="hasHeating"
            initialValue={false}
          />
          <BooleanInput
            label="Houtskachel/openhaard"
            source="hasFireplace"
            initialValue={false}
          />
          <BooleanInput label="WiFi" source="hasWifi" initialValue={false} />
          <BooleanInput label="Oven" source="hasStove" initialValue={false} />
          <BooleanInput
            label="Borden en bestek"
            source="hasPlatesAndCutlery"
            initialValue={false}
          />
          <BooleanInput
            label="Magnetron"
            source="hasMicrowave"
            initialValue={false}
          />
          <BooleanInput
            label="Koffiezetapparaat"
            source="hasCoffeeMachine"
            initialValue={false}
          />
          <BooleanInput
            label="Kookgerei"
            source="hasCookingSupplies"
            initialValue={false}
          />
          <BooleanInput
            label="Keuken"
            source="hasKitchen"
            initialValue={false}
          />
          <BooleanInput
            label="Koelkast"
            source="hasFridge"
            initialValue={false}
          />
          <BooleanInput
            label="Kledinghangers"
            source="hasClothesHangers"
            initialValue={false}
          />
          <BooleanInput
            label="Beddengoed"
            source="hasBedding"
            initialValue={false}
          />
          <BooleanInput
            label="Douchegel"
            source="hasShowerGel"
            initialValue={false}
          />
          <BooleanInput
            label="Shampoo"
            source="hasShampoo"
            initialValue={false}
          />
          <BooleanInput label="Tuin" source="hasGarden" initialValue={false} />
          <BooleanInput label="Schuur" source="hasBarn" initialValue={false} />
          <BooleanInput
            label="Rookmelder"
            source="hasSmokeAlarm"
            initialValue={false}
          />
          <BooleanInput
            label="Koolmonoxidemelder"
            source="hasCarbonMonoxideDetector"
            initialValue={false}
          />
          <BooleanInput
            label="Fohn"
            source="hasHairDryer"
            initialValue={false}
          />
          <BooleanInput
            label="Airconditioner"
            source="hasAirconditioning"
            initialValue={false}
          />
          <BooleanInput
            label="Wasmachine"
            source="hasWachingMachine"
            initialValue={false}
          />
        </FormTab>
        <FormTab label="Foto's">
          <ImageInput
            validate={[required()]}
            multiple
            labelMultiple="Upload foto's"
            source="files"
            accept="image/*"
            placeholder={<p>Plaats hier foto's van de woning.</p>}
          >
            <ImageField source="filename" />
          </ImageInput>
        </FormTab>
      </TabbedForm>
    </Create>
  );
};
