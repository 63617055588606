import { createMuiTheme } from "@material-ui/core/styles";
import { green, grey, red } from "@material-ui/core/colors";

const rawTheme = createMuiTheme({
  palette: {
    primary: {
      light: "#06753E",
      main: "#095E4B",
      dark: "#074537",
    },
    secondary: {
      main: "#054A6B",
      normal: "#81906D",
      light: "#4092B8",
      dark: "#032738",
    },
    warning: {
      main: "#f2713a",
    },
    error: red,
    success: green,
  },
  typography: {
    fontFamily:
      "'Comfortaa','HelveticaNeue','Helvetica Neue',Helvetica,Arial,sans-serif",
    fontSize: 14,
    fontWeightLight: 300, // Work Sans
    fontWeightRegular: 400, // Work Sans
    fontWeightMedium: 700,
    fontFamilyPrimary:
      "'Comfortaa','HelveticaNeue','Helvetica Neue',Helvetica,Arial,sans-serif",
    fontFamilySecondary:
      "'Comfortaa','HelveticaNeue','Helvetica Neue',Helvetica,Arial,sans-serif",
  },
});

const fontHeader = {
  color: rawTheme.palette.text.primary,
  fontWeight: 400,
  fontFamily:
    "'Comfortaa','HelveticaNeue','Helvetica Neue',Helvetica,Arial,sans-serif !important",
};

const theme = {
  ...rawTheme,
  palette: {
    ...rawTheme.palette,
    background: {
      ...rawTheme.palette.background,
      default: rawTheme.palette.common.white,
      placeholder: grey[200],
    },
  },
  typography: {
    ...rawTheme.typography,
    fontHeader,
    h1: {
      ...rawTheme.typography.h1,
      ...fontHeader,
      letterSpacing: 0,
      fontSize: 60,
      [rawTheme.breakpoints.down("sm")]: {
        fontSize: "2.5rem!important",
      },
    },
    h2: {
      ...rawTheme.typography.h2,
      ...fontHeader,
      fontSize: 48,
      [rawTheme.breakpoints.down("sm")]: {
        fontSize: "2.1rem!important",
      },
    },
    h3: {
      ...rawTheme.typography.h3,
      ...fontHeader,
      fontSize: 42,
      [rawTheme.breakpoints.down("sm")]: {
        fontSize: "2rem!important",
      },
    },
    h4: {
      ...rawTheme.typography.h4,
      ...fontHeader,
      fontSize: 36,
      [rawTheme.breakpoints.down("sm")]: {
        fontSize: "1.9rem!important",
      },
    },
    h5: {
      ...rawTheme.typography.h5,
      fontSize: 20,
      fontWeight: rawTheme.typography.fontWeightLight,
      [rawTheme.breakpoints.down("sm")]: {
        fontSize: "1.5rem!important",
      },
    },
    h6: {
      ...rawTheme.typography.h6,
      ...fontHeader,
      fontSize: 18,
      [rawTheme.breakpoints.down("sm")]: {
        fontSize: "1.1rem!important",
      },
    },
    subtitle1: {
      ...rawTheme.typography.subtitle1,
      fontSize: 18,
      [rawTheme.breakpoints.down("sm")]: {
        fontSize: "1.1rem!important",
      },
    },
    body1: {
      ...rawTheme.typography.body2,
      fontWeight: rawTheme.typography.fontWeightRegular,
      fontSize: 18,
      [rawTheme.breakpoints.down("sm")]: {
        fontSize: "1rem!important",
      },
    },
    body2: {
      ...rawTheme.typography.body1,
      fontSize: 16,
      [rawTheme.breakpoints.down("sm")]: {
        fontSize: ".9rem!important",
      },
    },
  },
};

export default theme;
