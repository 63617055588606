import * as React from "react";
import {
  ArrayInput,
  SimpleFormIterator,
  Create,
  Edit,
  List,
  Datagrid,
  BooleanInput,
  BooleanField,
  EmailField,
  PasswordInput,
  TextField,
  DeleteButton,
  EditButton,
  TextInput,
  SimpleForm,
  required,
  Show,
  SimpleShowLayout,
  Filter,
  useTranslate,
  SelectInput,
  SelectField,
  ImageInput,
  ImageField,
  ReferenceArrayInput,
  ReferenceArrayField,
  SingleFieldList,
  SelectArrayInput,
} from "react-admin";
import { makeStyles, Chip } from "@material-ui/core";

const useStyles = makeStyles({
  image: {
    height: "2rem",
    width: "2rem",
    borderRadius: "100%",
  },
});

const useQuickFilterStyles = makeStyles((theme) => ({
  chip: {
    marginBottom: theme.spacing(1),
  },
}));
const QuickFilter = ({ label }) => {
  const translate = useTranslate();
  const classes = useQuickFilterStyles();
  return <Chip className={classes.chip} label={translate(label)} />;
};

const PostFilter = (props) => (
  <Filter {...props}>
    <QuickFilter source="role" label="isAdmin" defaultValue={"admin"} />
    <QuickFilter source="isActive" label="isActive" defaultValue={true} />
  </Filter>
);

export const UserList = (props) => {
  const classes = useStyles();

  return (
    <List {...props} filters={<PostFilter />}>
      <Datagrid>
        <ImageField
          classes={classes}
          source="file.filename"
          label="Afbeelding"
        />
        <TextField source="username" label="Gebruikersnaam" />
        <EmailField source="email" label="Email" style={{ color: "#3f51b5" }} />
        <SelectField
          label="Rol"
          source="role"
          choices={[
            { id: "admin", name: "admin" },
            { id: "user", name: "user" },
            { id: "superuser", name: "superuser" },
          ]}
        />
        <ReferenceArrayField
          label="Portaal"
          reference="portals"
          source="portalId"
          link="show"
        >
          <SingleFieldList>
            <TextField source="name" validate={[required()]} />
          </SingleFieldList>
        </ReferenceArrayField>
        <BooleanField source="isActive" label="Is actief" />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};

export const UserShow = (props) => {
  const classes = useStyles();

  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="name" label="Naam" />
        <ImageField
          classes={classes}
          source="file.filename"
          label="Afbeelding"
        />
        <TextField source="username" label="Gebruikersnaam" />
        <EmailField source="email" label="Email" />
        <SelectField
          label="Rol"
          source="role"
          choices={[
            { id: "admin", name: "admin" },
            { id: "user", name: "user" },
            { id: "superuser", name: "superuser" },
          ]}
        />
        <ReferenceArrayField
          label="Portaal"
          reference="portals"
          source="portalId"
          link="show"
        >
          <SingleFieldList>
            <TextField source="name" validate={[required()]} />
          </SingleFieldList>
        </ReferenceArrayField>
        <BooleanField source="isActive" label="Is actief" />
      </SimpleShowLayout>
    </Show>
  );
};

export const UserEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" label="Naam" validate={[required()]} />
      <ImageInput
        validate={[required()]}
        maxSize={250000}
        source="file"
        label="Afbeelding"
        accept="image/*"
        placeholder={<p>Plaats hier de profielfoto.</p>}
      >
        <ImageField source="filename" />
      </ImageInput>
      <TextInput
        label="Email"
        source="email"
        type="email"
        validate={[required()]}
      />
      <SelectInput
        label="Rol"
        source="role"
        choices={[
          { id: "admin", name: "admin" },
          { id: "user", name: "user" },
          { id: "superuser", name: "superuser" },
        ]}
        validate={[required()]}
      />
      <ReferenceArrayInput
        label="Portaal"
        reference="portals"
        source="portalId"
      >
        <SelectArrayInput>
          <TextField source="name" validate={[required()]} />
        </SelectArrayInput>
      </ReferenceArrayInput>
      <BooleanInput source="isActive" label="Is actief" initialValue={false} />
    </SimpleForm>
  </Edit>
);

export const UserCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" label="Naam" validate={[required()]} />
      <ImageInput
        validate={[required()]}
        maxSize={250000}
        source="file"
        label="Afbeelding"
        accept="image/*"
        placeholder={<p>Plaats hier de profielfoto.</p>}
      >
        <ImageField source="filename" />
      </ImageInput>
      <TextInput
        label="Gebruikersnaam"
        source="username"
        validate={[required()]}
      />
      <TextInput
        label="Email"
        source="email"
        type="email"
        validate={[required()]}
      />
      <SelectInput
        label="Rol"
        source="role"
        choices={[
          { id: "admin", name: "admin" },
          { id: "user", name: "user" },
          { id: "superuser", name: "superuser" },
        ]}
        validate={[required()]}
      />
      <PasswordInput
        label="Wachtwoord"
        source="password"
        validate={[required()]}
      />
      <ReferenceArrayInput
        label="Portaal"
        reference="portals"
        source="portalId"
      >
        <SelectArrayInput>
          <TextField source="name" validate={[required()]} />
        </SelectArrayInput>
      </ReferenceArrayInput>
      <BooleanInput initialValue={false} source="isActive" label="Is actief" />
    </SimpleForm>
  </Create>
);
