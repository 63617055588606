export const isSmallFile = file => file.includes("-xs")

export const isLargeFile = file => file.includes()

export function getFileFromServer(file) {
  const xsFile = (file?.fileNames || []).find(isSmallFile)
  return `https://res.cloudinary.com/datails/image/upload/v1673074612/vakantie-vorden/${xsFile}`;
}

export function toOriginalFile(file) {
  return file.originalname
}
