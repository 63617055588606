export default {
  resources: {
    users: {
      name: "Gebruiker |||| Gebruikers",
    },
    properties: {
      name: "Accomodatie |||| Accomodaties",
    },
    reservations: {
      name: "Reservering |||| Reserveringen",
    },
    availability: {
      name: "Beschikbaarheid |||| Beschikbaarheid",
    },
    clients: {
      name: "Klant |||| Klanten",
    },
  },
};
